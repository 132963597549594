.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: calc(var(--spacing-unit) * 0.25) !important;
}
.pl-2 {
  padding-left: calc(var(--spacing-unit) * 0.5) !important;
}
.pl-3 {
  padding-left: var(--spacing-unit) !important;
}
.pl-4 {
  padding-left: calc(var(--spacing-unit) * 1.5) !important;
}
.pl-5 {
  padding-left: calc(var(--spacing-unit) * 3) !important;
}
